import React, { useEffect, useState } from "react";
import Details from "../../components/Details/Details";
import { useNavigate, useParams } from "react-router-dom";
import { IMG_URl } from "../../common/img_url";
import { Header } from "../../components/header";
import { indexStore } from "../../store/indexStore";
import { useTranslation } from "react-i18next";
import Visibility from "../../components/utils/Visibility";
import CirclesMenu from "../../components/MenusElements/CirclesMenu";
import IsNotActive from "../../components/IsNotActive/IsNotActive";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import Spacer from "../../components/Spacer";
import {
  getOSInfo,
  getUserLocation,
} from "../../components/utils/GetInfoScans";
function Branch(props) {
  const { t } = useTranslation();
  const {
    name,
    changeCategory,
    fetchMenusAction,
    menus,
    branch,
    restaurant,
    social,
    loading,
    description,
    fetchPriceOffersProducts,
  } = indexStore();
  const params = useParams();
  const paramsRequst = {
    domain: params.domain,
    branch_id: params.branch_id,
  };
  const naivgate = useNavigate();
  useEffect(() => {
    // console.log(visit);

    fetchMenusAction(paramsRequst).then(() => {
      fetchPriceOffersProducts(params.branch_id);
    });

    changeCategory(0);
  }, []);
  const onClickMenu = (e) => {
    naivgate(`${e}`);
  };
  const formattedDescription = branch[description]?.replace(/\n/g, "<br/>");

  return (
    <IsNotActive>
      <title>{paramsRequst.domain.toUpperCase()}</title>
      <Header noBack={true} />
      <Visibility visible={!loading}>
        <section
          className={`bg-primary p-4 flex  justify-between  flex-col gap-5 flex-grow text-onPrimary `}
        >
          <div className="flex flex-col gap-4 justify-between ">
            <h3 className="uppercase ">{restaurant[name]}</h3>
            {/* <h6 className="branch-name">{branch[description]}</h6> */}
            <h6
              className="leading-6 capitalize text-sm"
              dangerouslySetInnerHTML={{ __html: formattedDescription }}
            ></h6>

            <img
              className="w-28 aspect-square object-contain"
              src={`${IMG_URl}${restaurant.logo}`}
              alt=""
            />
          </div>
          <Visibility visible={menus.length !== 0}>
            <div className="flex flex-col gap-4 ">
              <p className="text-lg ">{t("gloabl.selectMenu")}</p>
              <CirclesMenu
                data={menus}
                displayWaygrid={true}
                onClickMenu={onClickMenu}
              />
            </div>
          </Visibility>
          <Details social={social} branch={branch} />
        </section>
      </Visibility>
      <Visibility visible={loading}>
        <RenderLoading />
      </Visibility>
    </IsNotActive>
  );
}

export default Branch;
const RenderLoading = () => {
  return (
    <div>
      <LoadingComponent
        width={150}
        height={20}
        count={2}
        gap={10}
        dir="column"
      />
      <LoadingComponent className="w-28 aspect-square" count={1} gap={10} />
      <Spacer height={20} />
      <LoadingComponent width={150} height={25} count={1} gap={10} />
      <Spacer height={20} />
      <LoadingComponent
        width={100}
        height={100}
        radius={"50%"}
        count={3}
        gap={10}
      />
    </div>
  );
};
