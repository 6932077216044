import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/fonts/ROBO.otf";
import "./i18n";
import "./style/output.css";
import * as Ably from "ably";
import { AblyProvider } from "ably/react";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
const client = new Ably.Realtime({
  key: "6jY4lw.wSFrTw:_FCLjMx0u5670bHjTKtGwKEj42beEriZu1190F-OY38",
});
root.render(
  <BrowserRouter>
    <AblyProvider client={client}>
      <App />
    </AblyProvider>
  </BrowserRouter>
);
