import React, { useEffect } from "react";

import Visibility from "../utils/Visibility";

function BottomSheet(props) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        props.toggleOpen(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div className="text-onBackground">
      {props.button}
      <Visibility visible={props.open}>
        <div
          className="fixed inset-0 z-10 bg-black bg-opacity-50"
          onClick={() => props.toggleOpen(false)}
        ></div>
      </Visibility>

      <div
        className={`fixed bottom-0 left-0 right-0 z-20 shadow-lg
           translate-y-full transition-transform bg-background ${
          props.open ? "!translate-y-0" : ""
        } ${props.className}`}
      >
        <div className="px-5 py-3 flex justify-between items-center border-b border-gray-400">
          <h5>{props.title}</h5>
          <button className="text-2xl" onClick={() => props.toggleOpen(false)}>
            &times;
          </button>
        </div>
        <div className="p-4">{props.children}</div>
      </div>
    </div>
  );
}

export default BottomSheet;
