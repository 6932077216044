import React, { useEffect, useRef, useState } from "react";
import Iterator from "../utils/Iterator";
import { IMG_URl } from "../../common/img_url";
import { indexStore } from "../../store/indexStore";
import Visibility from "../utils/Visibility";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import MenuCircle from "./MenuCircle";

function CirclesMenu(props) {
  const { name, lang } = indexStore();

  const scrollView = useRef(null);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const updateArrowVisibility = () => {
    const ref = scrollView.current;
    if (ref) {
      const hasOverflow = ref.scrollWidth > ref.clientWidth;
      if (!props.displayWaygrid) {
        setShowRightArrow(hasOverflow);
      }
    }
  };

  useEffect(() => {
    updateArrowVisibility();
    window.addEventListener("resize", updateArrowVisibility);
    return () => window.removeEventListener("resize", updateArrowVisibility);
  }, [props.displayWaygrid]);

  const onScroll = () => {
    const ref = scrollView.current;
    const scroll = Math.abs(ref.scrollLeft);
    setShowLeftArrow(scroll > 0);
    setShowRightArrow(Math.ceil(scroll) < ref.scrollWidth - ref.clientWidth);
  };

  const scrollLeft = () => {
    scrollView.current.scrollTo({
      left: scrollView.current.scrollLeft - 300, // Adjust the scroll amount as needed
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollView.current.scrollTo({
      left: scrollView.current.scrollLeft + 300, // Adjust the scroll amount as needed
      behavior: "smooth",
    });
  };
  ////////////////////////////////////////////////////////////

  return (
    <div className={`flex justify-between items-center gap-3 `}>
      <Visibility visible={showLeftArrow && !props.displayWaygrid}>
        <Visibility visible={lang !== "en"}>
          <IoIosArrowDropright
            className={` cursor-pointer text-2xl text-secondary  `}
            onClick={scrollRight}
          />
        </Visibility>
        <Visibility visible={lang === "en"}>
          <IoIosArrowDropleft
            className={` cursor-pointer text-2xl text-secondary  `}
            onClick={scrollLeft}
          />
        </Visibility>
      </Visibility>
      <div
        className={`flex w-full  justify-between overflow-x-auto  ${
          props.displayWaygrid
            ? " grid grid-cols-3  xs:grid-cols-4  md:grid-cols-5 gap-3 "
            : props.selectedMenu
            ? "px-3 gap-5"
            : ""
        }`}
        ref={scrollView}
        onScroll={onScroll}
      >
        <Iterator
          data={props.data}
          render={(e, i) => (
            <MenuCircle
              key={i}
              element={e}
              name={name}
              props={props}
              IMG_URl={IMG_URl}
            />
          )}
        />
      </div>
      <Visibility visible={showRightArrow && !props.displayWaygrid}>
        <Visibility visible={lang === "en"}>
          <IoIosArrowDropright
            className={`cursor-pointer text-2xl text-secondary  `}
            onClick={scrollRight}
          />
        </Visibility>
        <Visibility visible={lang !== "en"}>
          <IoIosArrowDropleft
            className={`cursor-pointer text-2xl text-secondary  `}
            onClick={scrollLeft}
          />
        </Visibility>
      </Visibility>
    </div>
  );
}

export default CirclesMenu;
